.gallery {

    width: 100%;

    &__items {

        display: grid;
        grid-gap: 20px;
        grid-template: repeat(3, 1fr) / repeat(3, 1fr);

        @media screen and (max-width: 756px) {

            display: flex;
            flex-direction: column;

        }

    }

    &__item {

        min-height: 180px;
        background: url('/images/header.jpg') no-repeat center;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        @media screen and (max-width: 756px) {

            margin-bottom: 10px;

        }

           &:nth-child(6n+1) {

                grid-column: span 2;
                grid-row: span 2;
                min-height: 380px;

                @media screen and (max-width: 756px) {

                    min-height: 180px;

                }

            }

    }

    &__item-title {

        color: #fff;
        padding: 16px 0 16px 24px;
        background: rgba(38, 34, 98, 0.8);

    }

}
