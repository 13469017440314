$font-default: 'Roboto', sans-serif;


$shadow-default: 3px 3px 3px 0 #b8b8b833;


$width-container: 1180px;


$color-black: #000;

$color-white: #FFF;

$color-gray: #707070;
$color-gray-lg: #F5F5F5;

$color-cyan: #0075cc;
$color-cyan-lg: #4cb3ff;
$color-cyan-dr: #0080ff;

$color-blue: #333271;
$color-blue-dark: #262262;


