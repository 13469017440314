.news-page {

    &__date {

        margin-bottom: 16px;
        font-size: 12px;
        line-height: 27px;
        color: $color-gray;

    }

    &__title {

        margin-bottom: 20px;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: $color-black;

    }

}
