.header {

    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    width: 100%;
    position: relative;

    &_inner {

        background-color: $color-blue-dark;

        &:after {
        
            display: none;
        
        }
    }

    &:after {

        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 292px;
        width: 100%;
        background: linear-gradient(179.7deg, rgba(0, 0, 0, 0.75) 1.65%, rgba(0, 0, 0, 0) 82.69%);

    }

    &__wrapper {

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        max-width: calc(#{$width-container} + 40px);
        width: 100%;
        padding: 10px 20px 20px;
        box-sizing: border-box;
        z-index: 1000;

        &_sticky {
        
            position: absolute;
            top: 0;
        
        }

        @media screen and (max-width: 876px), screen and (hover: none) {

            position: fixed;
            top: 0;
            background-color: $color-blue-dark;

        }
    }

    &__container {

        display: flex;
        width: 100%;

    }

    &__toolbar {

        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-grow: 1;
        margin-left: 40px;

        @media screen and (max-width: 876px), screen and (hover: none) {
            display: none;
        }
    }
}

.header-swiper {

    width: 100%;
    height: 600px;
    overflow: hidden;

    @media screen and (max-width: 586px) {

        height: 320px;

    }

    &__wrapper {

        display: flex;
        width: 100%;
        height: 100%;

        @media screen and (max-width: 586px) {

            margin-top: 90px;

        }
    }

    &__slide {

        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-shrink: 0;
        height: 100%;
        padding: 150px 0 90px;
        box-sizing: border-box;
        background: url('/images/header.jpg') no-repeat center / cover;
        text-decoration: none;

        @media screen and (max-width: 586px) {

            padding: 20px;

        }
    }

    &__slide-wrapper {

        display: flex;
        max-width: calc(#{$width-container} + 40px);
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;

    }

    &__announce {

        position: relative;

    }

    &__announce-text {

        position: relative;
        z-index: 2;
        font-size: 30px;
        line-height: 45px;
        color: #FFF;

        @media screen and (max-width: 586px) {

            font-size: 16px;
            line-height: 24px;

        }
    }

    &__announce-bg {

        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        filter: blur(30px);

    }

    &__pagination {

        position: absolute;
        width: 100%;
        bottom: 20px;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        height: 3px;

        @media screen and (max-width: 586px) {

            display: none;

        }
    }

    &__bullet {

        width: 30px;
        height: 100%;
        background-color: #F5F5F5;
        margin-right: 5px;
        z-index: 3;
        transition: .6s ease;


        &_active {


            background-color: #717171;
            transition: .6s ease;

        }

        &:last-child {

            margin: 0;

        }
    }

    &__prev, &__next {

        width: 25px;
        height: 25px;
        background: url('/images/icons/arrow-right.svg') no-repeat center;
        background-size: contain;
        cursor: pointer;
        position: absolute;
        margin: auto 0;
        top: 0;
        bottom: 0;
        z-index: 2;

        @media screen and (max-width: 586px) {

            display: none;

        }
    }

    &__prev {

        left: 60px;
        transform: rotateY(180deg);

    }

    &__next {

        right: 60px;

    }
}

.header-nav {

    width: 100%;
    margin-top: 30px;
    box-sizing: border-box;

    @media screen and (max-width: 876px), screen and (hover: none) {

        position: fixed;
        height: 100vh;
        top: 0;
        right: 0;
        transform: translateX(100%);
        width: 100%;
        max-width: 400px;
        border-top: 7px solid #EADE48;
        margin-top: 0;
        overflow-y: auto;
        background-color: #FFF;

        &_active {
            transform: translateX(0);
        }
    }
}

.header-search {

    display: flex;
    max-width: 540px;
    width: 100%;
    padding: 11px 6px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    margin-right: 22px;

    &__input {

        flex-grow: 1;
        background: transparent;
        border: 0;

    }

    &__button {

        width: 24px;
        height: 24px;
        padding: 0;
        border: 0;
        background: url('/images/icons/search.svg') no-repeat center / contain;
        cursor: pointer;
        margin-left: 10px;

    }
}

.accessibility  {

    width: 24px;
    height: 24px;
    background: url('/images/icons/accessibility.svg') no-repeat center / contain;
    cursor: pointer;
    margin-right: 20px;

}

.sitemap  {

    width: 17px;
    height: 17px;
    background: url('/images/icons/sitemap.svg') no-repeat center / contain;
    cursor: pointer;
    margin-right: 20px;

}
