.article-contacts {

    &__item {

        margin-bottom: 30px;
        padding-bottom: 30px;

        &:last-child {
        
            margin: 0;
        
        }
    }

    &__map {

        width: 100%;
        height: 300px;

    }
}
