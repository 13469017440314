@import 'hamburgers';

.hamburger {

    flex-shrink: 0;
    display: none;
    margin-left: 10px;
    padding: 0;
    z-index: 6;
    outline: none;
    height: 30px;
    width: 30px;
    position: fixed;
    top: 20px;
    right: 20px;

    @media screen and (max-width: 876px), screen and (hover: none) {
    
        display: block;
    
    }

    &-box {

        height: 100%;
        width: 100%;

    }

    &-inner,&-inner:before,&-inner:after {

        background-color: #EEE !important;

        width: 100%;
        height: 3px;

    }

    &-inner:before {

        top: -8px;

    }

    &-inner:after {

        bottom: -8px;

    }
}

.is-active {

    .hamburger-inner {
    
        background-color: #000 !important;
        transition: .6s ease;

        &:before, &:after {
        
            background-color: #000 !important;
            transition: .6s ease;

        } 
    }
}

