
.search-result {

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    flex-shrink: 0;
    flex-grow: 1;
    margin: 0 65px 0 0;

    &__item {

        text-decoration: none;
        font-size: 16px;
        color: $color-black;
        margin-bottom: 10px;

        &:last-child {

            margin: 0;

        }
    }
}

.search-line {

    display: flex;
    align-items: center;
    margin: 25px 0;

    &__find {
    
        margin: 0 20px 0 0;
    
    }

    &__reset {

        text-decoration: none;
        font-size: 18px;
        color: $color-black;

    }
}


// use BAM modifier


