.site-map {

    display: flex;
    flex-direction: column;

    &__wrapper {

        display: flex;
        flex-direction: column;
        margin-top: 40px;

    }
}

.site-map-list {

    list-style-type: none;
    margin-bottom: 15px;
    padding: 0;

    &_level_1, &_level_2 {

        padding-left: 20px;

    }

    &__item {

        display: flex;
        flex-direction: column;
        position: relative;

    }

    &__link {

        text-decoration: none;
        color: #000;

        &_level_0 {
        
            font-size: 18px;
            margin-bottom: 15px;
        
        }

        &_level_1, &_level_2 {

            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 300;

            &:before {

                content: '';
                display: flex;
                align-items: center;
                width: 30px;
                height: 2px;
                background-color: $color-blue; 
                margin-right: 5px;

            } 
        }

        &_level_2:before {
        
            width: 20px;
        
        }
    }
}
