.logo {

    display: flex;
    align-items: center;
    align-self: flex-start;

    &__image {
   
        background: url('/images/logo.svg') no-repeat center / contain;
        width: 80px;
        height: 80px;
        margin-right: 10px;

        @media screen and (max-width: 556px) {
        
            width: 60px;
            height: 60px;
        
        }
    }

    &__text {
   
        max-width: 203px;
        width: 100%;
        font-size: 16px;
        line-height: 20px;
        color: #fff;
        text-transform: uppercase; 

        @media screen and (max-width: 556px) {
    
            font-size: 12px;
            width: auto;
        
        }
    }
}
