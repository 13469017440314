.menu {

    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 876px), screen and (hover: none) {

        flex-direction: column;
        flex-wrap: nowrap;
        align-items: flex-end;
        background-color: #FFF;
        padding-top: 55px;

    }

    &_level-1 {

        display: none;
        flex-direction: column;
        flex-wrap: nowrap;
        position: absolute;
        height: auto;
        z-index: 10;
        top: 100%;
        background-color: $color-gray-lg;
        width: auto;
        border-radius: 5px;
        overflow: hidden;
        padding-top: 3px;
        min-width: 250px;
        max-width: 400px;
        animation-duration: .3s;

        @keyframes fade-in {

            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }

        @media screen and (max-width: 876px), screen and (hover: none) {

            display: flex;
            flex-wrap: nowrap;
            transition: height .5s, padding-bottom .5s;
            background-color: #FFF;
            position: relative;
            margin-bottom: 10px;
            top: 0;
            height: 0;

        }
    }

    &__item {

        list-style-type: none;

        @media screen and (max-width: 876px), screen and (hover: none) {
            text-align: right;
        }

        &:last-child {

            margin-right: 0;

        }

        &_level-0 {

            position: relative;
            transition: padding-bottom .6s;
            margin-right: 14px;
            padding: 10px 0;

            @media screen and (max-width: 876px), screen and (hover: none) {
                margin-right: 0;
                padding: 0 20px;
            }
            

            &:hover {


                @media screen and (max-width: 876px), screen and (hover: none) {
                    padding-bottom: 0;
                }

                &:last-child .menu_level-1 {
                    right: 0;
                }

                &:last-child::before{

                   border-top-left-radius: 0;
                   border-top-right-radius: 5px;

                }

                &::before {

                   content: "";
                   display: block;
                   position: absolute;
                   z-index: 100;
                   border-top-left-radius: 5px;
                   top: 100%;
                   width: 100%;
                   height: 3px;
                   background-color: #7851A9;

                    @media screen and (max-width: 876px), screen and (hover: none) {
                        display: none;
                    }
                }
        
            &.no-hover-before{
                &::before{
                    content: "";
                    display:  none;
                }
            }
                .menu_level-1 {

                    display: flex;
                    animation-name: fade-in;
                    
                    @media screen and (max-width: 876px), screen and (hover: none) {
                        
                        animation-name: none;
                    }
                }
            }
        }

        &_level-1 {
        
            position: relative;
            font-size: 16px;
            color: $color-black;
            z-index: 10;
            padding: 0 20px;
            
            @media screen and (max-width: 876px), screen and (hover: none) {
                padding: 0 0;
                width: 100%;
            }            

            &:hover {

                background-color: #E4E4E4;

            }
        }
    }

    &__link {

        &_level-0 {

            font-size: 14px;
            color: #FFF;
            text-transform: uppercase;
            width: 100%;

            @media screen and (max-width: 876px), screen and (hover: none) {

                position: relative;
                z-index: 10;
                color: #333271;
                padding: 5px 0;
                line-height: 30px;

            }
        }

        &_level-1 {

            position: relative;
            line-height: 24px;
            display: block;
            padding: 5px 0;
            z-index: 10;

            @media screen and (max-width: 876px), screen and (hover: none) {
                line-height: 16px;
            }
        }
    }
}
