%width-container {

    max-width: calc(#{$width-container} + 40px);
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 2;

}

.footer {

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-blue;

    &__wrapper {

        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

    }

    &__content {

        @extend %width-container;
        padding-bottom: 50px;
        padding-top: 60px;

        @media screen and (max-width: 976px) {

            grid-template-columns: 1fr;
            gap: 7px;

        }
    }

    &__item {

    }
}

.footer-menu {

    display: flex;
    padding: 0;
    margin: 0;

    &_level-0 {

        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 30px;

        @media screen and (max-width: 976px) {

            grid-template-columns: 1fr;
            gap: 7px;

        }
    }

    &_level-1 {

        flex-direction: column;

        @media screen and (max-width: 976px) {

            display: none;

        }
    }

    &__item {

        padding: 0;
        list-style-type: none;
        margin-bottom: 12px;

        &_level-0 > .footer-menu__link_level-0 {
        

            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            color: #FFF;
            margin-bottom: 15px;
        
        }

        &_level-1 {
       
            display: flex;
            justify-content: flex-start;
        
        }

        &:first-child {

            grid-area: 1 / 1 / 3 / 2;

        }


        &:last-child {

            margin-bottom: 0;
            border: none;

        }
    }

    &__link {

        display: flex;
        color: #FFF;

        &_level-1 {

            align-self: flex-start;
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 7px;
            border-bottom: 1px solid transparent;
            transition: .6s ease;

            @media screen and (max-width: 976px) {

                font-size: 14px;
                line-height: 16px;
                color: $color-blue-dark;
                text-transform: uppercase;
                margin-bottom: 20px;

            }


            &:hover {

                border-color: #fff;
                transition: .6s ease;

            }

            &:last-child {

                margin-bottom: 0;

            }
        }
    }
}

.footer-bottom {

    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 35px;
    padding-bottom: 60px;
    border-top: 1px solid #FFF;

    &__wrapper {

        @extend %width-container;
        display: flex;
        justify-content: space-between;

    }

    &__item {

        margin-right: 30px;
        color: #FFF;

        @media screen and (max-width: 976px) {

            display: none;

        }

        &:last-child {

            max-width: 510px;
            margin-right: 0;

        }

        &_address {

            display: flex;

            @media screen and (max-width: 976px) {

                text-align: right;
                margin: 0;

            }
        }
    }
}

.footer-social {

    @media screen and (max-width: 976px) {

        display: none;

    }

    &_mobile {

        display: none;

        @media screen and (max-width: 976px) {

            display: flex;
            margin-right: 50px;

        }
    }

    &__title {

        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        margin-bottom: 15px;
        color: #FFF;

    }

    &__wrapper {

        display: flex;
        flex-wrap: wrap;

    }

    &__icon {

        background: no-repeat center / contain;

        &_vk {

            width: 24px;
            height: 14px;
            background-image: url('/images/icons/vk.svg');

        }
    }
}


