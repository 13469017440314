.html, .body {

    width: 100%;
    min-height: 100vh;

}

.body {

    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    font-family: $font-default;
    color: #000;

}

a {

    text-decoration: none;
    color: initial;

}

.main {

    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    position: relative;

    &__wrapper {

        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: calc(#{$width-container} + 40px);
        width: 100%;
        height: 100%;
        padding: 10px 20px 20px;
        box-sizing: border-box;

        &_main {
        
            max-width: none;
            padding: 0;
        
        }
    }
}

.article {

    display: flex;
    flex-direction: column;
    width: 100%;

}


