.section-news {

    display: flex;
    flex-direction: column;
    max-width: calc(#{$width-container} + 40px);
    width: 100%;
    padding: 50px 20px 40px;
    box-sizing: border-box;

    &__top-line {

        display: flex;
        justify-content: space-between;
        align-items: center;

    }

    &__show-more {

        margin: 0 0 30px;
        border-bottom: 1px solid $color-black;
        transition: .3s ease;

        &:hover {

            border-color: transparent;
            transition: .3s ease;

        }

    }

    &__wrapper {

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;

        @media screen and (max-width: 756px) {

            grid-template-columns: repeat(2, 1fr);

        }

        @media screen and (max-width: 556px) {

            grid-template-columns: 1fr;

        }
    }

    &__item {

        display: flex;
        flex-direction: column;

        @media screen and (max-width: 556px) {

            flex-direction: row;

        }

        &-image {

            flex-shrink: 0;
            width: 100%;
            height: 240px;
            background: url('/images/section-news__item-image.png') no-repeat center / contain;
            margin-bottom: 20px;

            @media screen and (max-width: 556px) {

                max-width: 90px;
                height: 120px;
                background-position: center 0;
                margin: 0;
                margin-right: 10px;

            }
        }

        &-wrapper {

            display: flex;
            flex-direction: column;
        
        }

        &-title {

            font-size: 18px;
            line-height: 27px;
            font-weight: 500;
            margin-bottom: 15px;

            @media screen and (max-width: 856px) {

                font-size: 14px;
                line-height: 20px;

            }
        }

        &-date {

            font-size: 14px;
            color: #9B9B9B;

        }
    }
}

.section-announce {

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: radial-gradient(6000px at 110% 0, #002f55 0, #08e8de 100%);

    &__container {

        max-width: calc(#{$width-container} + 40px);
        width: 100%;
        padding: 50px 20px 40px;
        box-sizing: border-box;

    }

    &__wrapper {

        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;

        @media screen and (max-width: 1156px) {

            grid-template-columns: repeat(3, 1fr);

        }

        @media screen and (max-width: 756px) {

            grid-template-columns: repeat(2, 1fr);

        }

        @media screen and (max-width: 556px) {

            grid-template-columns: 1fr;

        }
    }

    &__item {

        min-height: 180px;
        display: flex;
        flex-direction: column;
        position: relative;

        &-image {

            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url('/images/section-news__image.jpg') no-repeat center / cover;

            &:after {

                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba(0,0,0,.8);
                z-index: 3; 

            }
        }

        &-wrapper {

            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            z-index: 3;
            padding: 20px;
            box-sizing: border-box;
        }

        &-title {

            font-size: 18px;
            line-height: 21px;
            font-weight: 500;
            color: #FFF;
            margin-bottom: 10px;

        }

        &-wrapper-text {

            display: flex;
            flex-direction: column;

        }

        &-date {

            font-size: 16px;
            line-height: 19px;
            color: #FFF;
            margin-bottom: 16px;

        }

        &-category {


            font-size: 12px;
            line-height: 14px;
            color: #FFF;
            text-transform: uppercase;

        }
    }
}

.section-partners {

    display: flex;
    flex-direction: column;
    max-width: calc(#{$width-container} + 40px);
    width: 100%;
    padding: 50px 20px 40px;
    box-sizing: border-box;

    @media screen and (max-width: 856px) {

        display: none;

    }

    &__wrapper {

        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 120px 48px;

    }

    &__item {

        display: flex;
        flex-direction: column;
        align-items: center;

        &-image {

            width: 100%;
            height: 150px;
            background: url('/images/swiper-partners__image.png') no-repeat center / contain;
            margin-bottom: 20px;

        }

        &-title {

            font-size: 15px;
            line-height: 18px;

        }
    }
}

.section-links {

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: radial-gradient(100px at 50% 50%,#7851a9 0,#08e8de 1000%);
    padding: 50px 0 40px;

    &__wrap {

        max-width: calc(#{$width-container} + 40px);
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;

    }

    &__wrap-slider {
   
        position: relative;
        padding: 0 190px;
        box-sizing: border-box;

        @media screen and (max-width: 1176px) {
        
            padding: 0 40px;
        
        }

        @media screen and (max-width: 476px) {

            padding: 0;

        }
    }

    &__container {

        box-sizing: border-box;
        overflow: hidden;


    }

    &__wrapper {

        display: flex;

    }

    &__prev, &__next {

        width: 15px;
        height: 15px;
        background: url('/images/icons/arrow-right.svg') no-repeat center;
        background-size: contain;
        cursor: pointer;
        position: absolute;
        margin: auto 0;
        top: 0;
        bottom: 0;
        z-index: 5;

        @media screen and (max-width: 476px) {
        
            display: none;
        
        }
    }

    &__prev {

        left: 100px;
        transform: rotateY(180deg);

        @media screen and (max-width: 1176px) {
        
            left: 5px;
        
        }
    }

    &__next {

        right: 100px;

        @media screen and (max-width: 1176px) {
        
            right: 5px;
        
        }
    }

    &__item {

        position: relative;
        min-height: 150px;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        padding: 20px;
        box-sizing: border-box;
        background: #FFF no-repeat center / cover;

        &-image {

            flex-shrink: 0;
            width: 94px;
            height: 100%;
            margin-right: 10px;
            background: url('/images/section-links__image.png') no-repeat center / cover;

        }

        &-title {

            font-size: 18px;
            line-height: 21px;
            font-weight: 500;
            color: initial;

        }
    }
}
