.path {

    @media screen and (max-width: 776px) {

        margin-bottom: 25px;

    }

    &__wrapper-padding {

        padding: 0 15px;

    }

    &__wrapper {

        display: flex;
        align-items: center;
        padding: 0;

    }

    &__item {

        list-style-type: none;
        position: relative;
        margin-left: 23px;
        display: flex;
        align-items: center;
        margin-left: 15px;
        padding-left: 15px;

        @media screen and (max-width: 526px) {

            margin-left: 10px;
            padding-left: 10px;

        }

        &:before {

            content: '';
            position: absolute;
            height: 4px;
            width: 4px;
            background-color: $color-blue-dark;
            border-radius: 50%;
            left: 0;


        }

        &:first-child {

            margin-left: 0;
            text-decoration: none;
            padding: 0;
            margin: 0;

            &:before {

                display: none;

            }

        }



        @media screen and (max-width: 636px) {

            &:nth-child(4), &:nth-child(5), &:nth-child(6) {

                display: none;

            }
        }

        &:last-child .path__link {

            color: $color-gray;

        }

        &:nth-child(-n+2) {

            .path__link {

                text-decoration: none;
                white-space: nowrap;

            }

        }

    }

    &__link {

        color: $color-blue-dark;
        white-space: normal;

        @media screen and (max-width: 526px) {

            font-size: 12px;

        }

        @media screen and (max-width: 360px) {

            font-size: 10px;

        }
    }
}
