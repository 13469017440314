.article-news {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &__wrapper {

        margin-top: 60px;
        margin-bottom: 175px;
        display: flex;
        flex-direction: column;

    }

}

.article-news-item {

    margin-bottom: 112px;
    width: 100%;
    display: flex;

    &:last-child {

        margin-bottom: 0;

    }

    @media screen and (max-width: 756px) {

        margin-bottom: 56px;

    }

    &__wrapper {

        width: 100%;
        display: flex;

        @media screen and (max-width: 756px) {

            flex-direction: column;

        }

    }

    &__image {

        margin-right: 64px;
        width: 30%;
        height: 100%;
        min-width: 300px;
        min-height: 205px;
        background: url(/images/header.jpg) no-repeat center/cover;

        @media screen and (max-width: 756px) {


            margin-right: 0;
            margin-bottom: 15px;
            width: 100%;
            min-height: 285px;
            min-width: unset;

        }
    }

    &__text-wrapper {

        width: 70%;
        height: 100%;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 756px) {

            width: 100%;

        }

    }

    &__date {

        color: $color-gray;
        font-size: 12px;
        line-height: 27px;

    }

    &__anounce {

        margin-bottom: 10px;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        color: $color-black;

    }

    &__text {

        font-size: 16px;
        line-height: 27px;
        color: $color-black;

    }

}
