.button {

    display: flex;
    box-sizing: border-box;
    cursor: pointer;

}

// use BAM modifier
 
