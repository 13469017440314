.popup {
    position: fixed;
    width: 40vw;
    height: 20vh;
    top: 40vh;
    left: 30vw;
    background-color: #fff;
    z-index: 5000;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 600px) {
        width: 80vw;
        left: 10vw;
    }
}