.title {

    font-size: 32px;
    font-weight: 500;
    margin: 0 0 30px;

    @media screen and (max-width: 536px) {

        font-size: 18px;

    }

    &_white {

        color: #fff;

    }
}

// use BAM modifier
